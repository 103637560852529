import { Tag } from '@/components/tag';

import {DataTable} from "@/components/table/datatable";
import {ColumnDef} from "@tanstack/react-table";
import {ThemeColor} from "@/lib/utils";
import {useDispatch, useSelector} from "react-redux";
import {idrFormatter} from "@/lib/utils/currency";
import {useEffect, useState} from "react";
import {AppDispatch} from "@/root/store";
import {ApproveWithdraw, GetBalances, GetWithdraws} from "@/services/financial";
import moment from "moment";
import {useLocation} from "react-router-dom";
import {Button} from "@/components/button";
import {CheckIcon} from "@heroicons/react/24/solid";
import {Simulate} from "react-dom/test-utils";
import submit = Simulate.submit;


interface Withdraws {
  id: string;
  name: string;
  email: string;
  username: string;
  settled_balance: number;
  pending_balance: number;
  stats: {
    withdraws_count: number,
    revenues_count: number,
    revenues_total: number,
    withdraws_total: number,
    tickets_sold_count: number
  }
}

export function OrgCommerceBalances() {
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [page, setPage] = useState(1);

  const columns: ColumnDef<Withdraws>[] = [
    {
      accessorKey: 'name',
      header: 'Organizer',
      cell: ({row}) => {
        return(
            <>
              <div>{row.original.name}</div>
              <div className='font-normal text-neutral-70'>
                {row.original.email}
              </div>
            </>
        )
      }
    },
    {
      header: 'Saldo Dapat Ditarik',
      accessorKey: 'settled_balance',
      cell: ({ row }) => {
        const nominal = row.original.settled_balance;
        return (
            <span className='text-danger'>{idrFormatter.format(nominal)}</span>
        );
      },
    },
    {
      header: 'Saldo Ditahan',
      accessorKey: 'pending_balance',
      cell: ({ row }) => {
        const nominal = row.original.pending_balance;
        return (
            <span className='text-danger'>{idrFormatter.format(nominal)}</span>
        );
      },
    },
    {
      header: 'Jumlah Penarikan',
      accessorKey: 'withdraws_count',
      cell: ({ row }) => {
        const nominal = row.original.stats.withdraws_count;
        return (
            <span className='text-danger'>{nominal}</span>
        );
      },
    },
    {
      header: 'Total Penarikan',
      accessorKey: 'withdraws_total',
      cell: ({ row }) => {
        const nominal = row.original.stats.withdraws_total;
        return (
            <span className='text-danger'>{idrFormatter.format(nominal)}</span>
        );
      },
    },
    {
      header: 'Jumlah Tiket Terjual',
      accessorKey: 'tickets_sold_count',
      cell: ({ row }) => {
        const nominal = row.original.stats.tickets_sold_count;
        return (
            <span className='text-danger'>{nominal}</span>
        );
      },
    },
    {
      header: 'Jumlah Pemasukan',
      accessorKey: 'revenues_count',
      cell: ({ row }) => {
        const nominal = row.original.stats.revenues_count;
        return (
            <span className='text-danger'>{nominal}</span>
        );
      },
    },
    {
      header: 'Total Pemasukan',
      accessorKey: 'revenues_total',
      cell: ({ row }) => {
        const nominal = row.original.stats.revenues_total;
        return (
            <span className='text-danger'>{idrFormatter.format(nominal)}</span>
        );
      },
    }
  ]

  const {balances, paginator} = useSelector((state: any) => state.financial);

  const queryParams = new URLSearchParams(location.search);
  const searchQuery: string = queryParams.get('search') ?? '';

  useEffect(() => {
    dispatch(GetBalances(searchQuery, page));
  }, [searchQuery, page])

  return (
    <>
      <DataTable
          name='Daftar Pemilik Saldo'
          noun='pemilik saldo'
          columns={columns}
          data={balances}
          total={paginator.to}
          currentTotal={paginator.total}
          currentPage={paginator.current_page}
          pages={paginator.last_page}
          onPageRequest={(nextPage) => setPage(nextPage)}
      />
    </>
  );
}
