import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {events, loading, getErrors, visitors, transactions, stats} from "@/reducers/dashboard";

export const GetStats = ():  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = await axios
        .get(process.env.REACT_APP_BASE_URL + '/employee/dashboard', {
            headers: headers()
        }).then((response) => {
            dispatch(stats(response.data.data.stats));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};
