import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    events: [],
    stats: {
        total_active_events: 0,
        total_pending_events: 0,
        total_organizers: 0,
        total_tickets_sold: 0
    },
    transactions: [],
    visitors: [],
    loading: false,
    errorMsg: '',
    errors: {}
};

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        events: (state, action) => {
            state.events = action.payload;
        },
        visitors: (state, action) => {
            state.visitors = action.payload;
        },
        transactions: (state, action) => {
            state.transactions = action.payload;
        },
        stats: (state, action) => {
            state.stats = action.payload;
        },
        getErrors: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        clearErrors: (state) => {
            state.errorMsg = '';
            state.errors = '';
        },
        loading: (state, action) => {
            state.loading = action.payload;
        }
    },
});

export const { loading, events, visitors, transactions, stats, clearErrors, getErrors } = dashboardSlice.actions;
export default dashboardSlice.reducer;
