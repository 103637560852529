import { Tag } from '@/components/tag';

import {DataTable} from "@/components/table/datatable";
import {ColumnDef} from "@tanstack/react-table";
import {ThemeColor} from "@/lib/utils";
import {useDispatch, useSelector} from "react-redux";
import {idrFormatter} from "@/lib/utils/currency";
import {useEffect, useState} from "react";
import {AppDispatch} from "@/root/store";
import {ApproveWithdraw, GetWithdraws} from "@/services/financial";
import moment from "moment";
import {useLocation} from "react-router-dom";
import {Button} from "@/components/button";
import {CheckIcon} from "@heroicons/react/24/solid";
import {Simulate} from "react-dom/test-utils";
import submit = Simulate.submit;


interface Withdraws {
  id: string;
  reference: string;
  amount_requested: number;
  transfer_fee: number;
  amount_transferred: number;
  balance_after_withdraw: number;
  balance_before_withdraw: number;
  date: string;
  bank: {
    bank_code: string,
    account_holder_name: string,
    account_number: number,
  },
  state: {
    label: string,
    color: ThemeColor
  };
}

export function OrgCommerceWithdrawal() {
  const location = useLocation();
  const dispatch: AppDispatch = useDispatch();
  const [page, setPage] = useState(1);

  const columns: ColumnDef<Withdraws>[] = [
    {
      header: 'Kode Penarikan',
      accessorKey: 'reference',
    },
    {
      header: 'Pemilik Rekening',
      accessorKey: 'holder_name',
      cell: ({ row }) => {
        const holderName = row.original?.bank?.account_holder_name;
        return (
            <span>{holderName}</span>
        );
      },
    },
    {
      header: 'No. Rekening',
      accessorKey: 'account_no',
      cell: ({ row }) => {
        const accountNumber = row.original?.bank?.account_number;
        return (
            <span>{accountNumber}</span>
        );
      },
    },
    {
      header: 'Nama Bank',
      accessorKey: 'bank_name',
      cell: ({ row }) => {
        const bankCode = row.original?.bank?.bank_code;
        return (
            <span>{bankCode}</span>
        );
      },
    },
    {
      header: 'Jumlah',
      accessorKey: 'amount_requested',
      cell: ({ row }) => {
        const nominal = row.original.amount_requested;
        return (
            <span className='text-danger'>{idrFormatter.format(nominal)}</span>
        );
      },
    },
    {
      header: 'Biaya',
      accessorKey: 'transfer_fee',
      cell: ({ row }) => {
        const nominal = row.original.transfer_fee;
        return (
            <span className='text-danger'>{idrFormatter.format(nominal)}</span>
        );
      },
    },
    {
      header: 'Total Diterima',
      accessorKey: 'amount_transferred',
      cell: ({ row }) => {
        const nominal = row.original.amount_transferred;
        return (
            <span className='text-danger'>{idrFormatter.format(nominal)}</span>
        );
      },
    },
    {
      header: 'Saldo Sebelum Penarikan',
      accessorKey: 'balance_after_withdraw',
      cell: ({ row }) => {
        const nominal = row.original.balance_before_withdraw;
        return (
            <span className='text-danger'>{idrFormatter.format(nominal)}</span>
        );
      },
    },
    {
      header: 'Saldo Setelah Penarikan',
      accessorKey: 'balance_after_withdraw',
      cell: ({ row }) => {
        const nominal = row.original.balance_after_withdraw;
        return (
            <span className='text-danger'>{idrFormatter.format(nominal)}</span>
        );
      },
    },
    {
      header: 'Tanggal Penarikan',
      accessorKey: 'date',
      cell: ({ row }) => {
        const transactionDate = row.original.date;
        return moment(transactionDate).format('llll');
      },
    },
    {
      header: 'Status',
      accessorKey: 'state',
      cell: ({ row }) => {
        const state = row.original.state;
        return <Tag color={state.color}>{state.label}</Tag>;
      },
    },
    {
      header: 'Aksi',
      accessorKey: 'action',
      cell: ({ row }) => {
        const state = row.original.state;
        return state.label === "Pending" && <Button variant={'success'} onClick={() => {
          handleApproveWithdraw(row.original.id)}
        } rightIcon={<CheckIcon />} />;
      },
    }
  ]

  const {withdraws, withdraw, paginator} = useSelector((state: any) => state.financial);

  const queryParams = new URLSearchParams(location.search);
  const searchQuery: string = queryParams.get('search') ?? '';

  const handleApproveWithdraw = (withdrawId: string) => {
    dispatch(ApproveWithdraw(withdrawId))
  }

  useEffect(() => {
    dispatch(GetWithdraws(searchQuery, page));
  }, [searchQuery, withdraw, page])

  return (
    <>
      <DataTable
          name='Daftar Penarikan'
          noun='penarikan'
          columns={columns}
          data={withdraws}
          total={paginator.to}
          currentTotal={paginator.total}
          currentPage={paginator.current_page}
          pages={paginator.last_page}
          onPageRequest={(nextPage) => setPage(nextPage)}
      />
    </>
  );
}
