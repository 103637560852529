import {useEffect} from 'react';
import {Navigate, Outlet, useLocation} from 'react-router-dom';
import {DashboardCard} from '../dashboard/_card';
import {SectionItem, SectionTabs} from '../_nav/section';
import {useDispatch, useSelector} from "react-redux";
import {FormatCurrency} from "@/routes/root";
import {GetFinancialStats} from "@/services/financial";
import {AppDispatch} from "@/root/store";

export function OrgCommerceLayout() {
    const location = useLocation();
    const dispatch: AppDispatch = useDispatch();

    const pathArray = location.pathname.split('/').slice(2);
    const section = pathArray[0];

    const {stats} = useSelector((state: any) => state.financial);

    useEffect(() => {
        dispatch(GetFinancialStats());
    }, []);

    return !section ? (
        <Navigate to='payment'/>
    ) : (
        <>
            <div className='grid grid-cols-2 gap-2 pb-2 sm:gap-6 md:grid-cols-3 xl:grid-cols-4'>
                <DashboardCard
                    variant='white'
                    name='Volume Transaksi'
                    topLabel={{primary: <FormatCurrency amount={stats.transaction_volume}/>}}
                />
                <DashboardCard
                    variant='whiteAlt'
                    name='Total Transaksi'
                    topLabel={{primary: <FormatCurrency amount={stats.total_transactions}/>}}
                />
                <DashboardCard
                    variant='yellow'
                    name='Saldo Klien'
                    topLabel={{primary: <FormatCurrency amount={stats.client_balances}/>}}

                />
                <DashboardCard
                    variant='blue'
                    name='Keuntungan'
                    topLabel={{primary: <FormatCurrency amount={stats.app_profits}/>}}
                />
            </div>
            <SectionTabs>
                <SectionItem
                    id='balances'
                    name='Daftar pemilik saldo'
                    isCurrent={section === 'balances'}
                />
                <SectionItem
                    id='payment'
                    name='Daftar pemasukan'
                    isCurrent={section === 'payment'}
                />
                <SectionItem
                    id='withdrawal'
                    name='Daftar penarikan'
                    isCurrent={section === 'withdrawal'}
                />
            </SectionTabs>
            <Outlet/>
        </>
    );
}
