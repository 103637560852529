import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {setUsers, getErrors, loading, setPaginator} from "@/reducers/user";

export const GetUsers = (query?: string, page = 1):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    return axios
        .get(process.env.REACT_APP_BASE_URL + '/employee/users?filter[search]=' + query + '&page=' + page, {
            headers: headers()
        }).then((response) => {
            dispatch(setUsers(response.data.data));
            dispatch(setPaginator(response.data.meta));
            // dispatch(setExportRoute(response.data.export_route));

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });
};
