import {Alert} from '@/components/alert';
import {Button} from '@/components/button';
import {HSeparator} from '@/components/separator';
import {NamedTable} from '@/components/table/namedtable';
import {Tag} from '@/components/tag';
import {
    ArrowDownCircleIcon,
    ArrowUpCircleIcon,
    EyeIcon,
} from '@heroicons/react/24/solid';
import {DashboardCard} from './_card';
import {DashboardEventCard} from './_eventcard';
import {useDispatch, useSelector} from "react-redux";
import {useEffect} from "react";
import {AppDispatch} from "@/root/store";
import {GetStats} from "@/services/dashboard";
import moment from "moment";
import {FormatCurrency} from "@/routes/root";
import {Bar, Doughnut} from "react-chartjs-2";
import {Chart as ChartJS, ArcElement, Tooltip, Legend, CategoryScale, BarElement, Title, LinearScale} from 'chart.js';

export const data = {
    labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    datasets: [
        {
            label: '# of Votes',
            data: [12, 19, 3, 5, 2, 3],
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
};

export function OrgDashboard() {
    const dispatch: AppDispatch = useDispatch();
    const {events, stats, visitors, transactions} = useSelector((state: any) => state.dashboard);

    ChartJS.register(ArcElement, CategoryScale,
        LinearScale,
        BarElement,
        Title,
        Tooltip,
        Legend);

    useEffect(() => {
        dispatch(GetStats())
    }, [dispatch]);

    return (
        <>
            <div className='grid grid-cols-2 gap-2 pb-2 sm:gap-6 md:grid-cols-3 xl:grid-cols-4'>
                <DashboardCard
                    variant='yellow'
                    name='Event Aktif'
                    topLabel={{primary: stats.total_active_events, secondary: 'event'}}
                />
                <DashboardCard
                    variant='white'
                    name='Pending Event'
                    topLabel={{primary: stats.total_pending_events, secondary: 'event'}}
                />
                <DashboardCard
                    variant='blue'
                    name='Penyelenggara'
                    topLabel={{primary: stats.total_organizers, secondary: 'penyelenggara'}}
                />
                <DashboardCard
                    variant='whiteAlt'
                    name='Tiket Terjual'
                    topLabel={{primary: stats.total_tickets_sold, secondary: 'tiket'}}
                />
            </div>

            <HSeparator/>

            <div className='grid lg:grid-cols-2 gap-6 lg:flex-row lg:items-start'>
                <div className={'flex flex-col gap-6'}>
                    <NamedTable
                        title='Total Transaksi'
                        ctaButton={undefined}
                        className='flex-grow'
                    >
                        <Bar data={data}/>
                    </NamedTable>
                    <NamedTable
                        title='Jumlah Transaksi'
                        ctaButton={undefined}
                        className='flex-grow'
                    >
                        <Bar data={data}/>
                    </NamedTable>
                </div>

                <HSeparator className='block lg:hidden'/>

                <NamedTable
                    title='Metode Pembayaran'
                    ctaButton={undefined}
                    className='flex-grow'
                >
                    <Doughnut
                        className={'my-12'}
                        data={data}
                    />
                </NamedTable>
            </div>
        </>
    );
}
