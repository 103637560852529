import { Breadcrumb, BreadcrumbRouteTree } from '@/components/breadcrumb';
import { Throbber } from '@/components/throbber';
import { cn } from '@/lib/utils';
import {Bars3Icon, ArrowRightStartOnRectangleIcon} from '@heroicons/react/24/outline';
import {
  BanknotesIcon,
  UserGroupIcon,
  CalendarDaysIcon,
  ChartPieIcon,
  CircleStackIcon,
  UsersIcon,
  CogIcon, XMarkIcon, TrashIcon, CreditCardIcon
} from '@heroicons/react/24/solid';
import { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { SideNav } from './_nav';

import styles from './OrgLayout.module.css';
import {Modal} from "@/components/modal";
import {Button} from "@/components/button";
import {AppDispatch} from "../../store";
import {useDispatch, useSelector} from "react-redux";
import {logout} from "@/reducers/auth";

const routeTree: BreadcrumbRouteTree = {
  dashboard: {
    name: 'Dashboard',
    icon: ChartPieIcon,
    children: {
      attendees: {
        name: 'Daftar Peserta',
      },
    },
  },
  organizer: {
    name: 'Organizer',
    icon: CircleStackIcon,
    children: {
      '*': {
        name: 'Detail Organizer',
        children: {
          info: {
            name: 'Info'
          },
          security: {
            name: 'Password'
          },
          event: {
            name: 'Event'
          },
        },
      },
    },
  },
  user: {
    name: 'User',
    icon: UsersIcon,
    children: {
      '*': {
        name: 'Detail User',
        children: {
          info: {
            name: 'Info'
          },
          security: {
            name: 'Password'
          },
          transaction: {
            name: 'Transaksi'
          },
        },
      },
    },
  },
  guest: {
    name: 'Guest',
    icon: UserGroupIcon,
    children: {
      '*': {
        name: 'Detail Guest',
        children: {
          info: {
            name: 'Info'
          },
          transaction: {
            name: 'Transaksi'
          },
          ticket: {
            name: 'Tiket'
          },
        },
      },
    },
  },
  event: {
    name: 'Event',
    icon: CalendarDaysIcon,
    children: {
      new: {
        name: 'Buat Event',
        children: {
          '*': {
            name: '',
            hidden: true,
          },
          'custom-plan': {
            name: 'Custom Paket',
          },
          checkout: {
            name: '',
            hidden: true,
          },
        },
      },
      '*': {
        name: 'Detail Event',
        children: {
          voucher: {
            name: 'Voucher',
            hidden: true,
            children: {
              '*': {
                name: 'Detail Voucher',
              },
            },
          },
        },
      },
    },
  },
  commerce: {
    name: 'Keuangan',
    icon: BanknotesIcon,
    hidden: true,
    break: true,
  },
  committee: {
    name: 'Metode Pembayaran',
    icon: CreditCardIcon,
  },
  setting: {
    name: 'Pengaturan',
    icon: CogIcon,
  },
};

export function OrgLayout() {
  const location = useLocation();
  const [logoutWarnModal, setLogoutWarnModal] = useState(false);
  const dispatch: AppDispatch = useDispatch();

  const { loading, isLoggedOut } = useSelector((state: any) => state.auth);

  const authToken = localStorage.getItem('authToken');

  const pathArray = location.pathname.substring(0).split('/').slice(1);
  const section = routeTree[pathArray[0]];

  const [nav, setNav] = useState(false);
  useEffect(() => setNav(false), [location]);

  useEffect(() => {
    if (!authToken || isLoggedOut) {
      window.location.href = '/auth/login';
    }
  }, [authToken, isLoggedOut]);

  const coverStyle = cn(
    styles.coverWrapper,
    nav ? `${styles.isOpen} pointer-events-auto` : 'pointer-events-none',
    'fixed h-screen w-screen lg:hidden'
  );

  /*<div className='relative h-[calc(100dvh)] w-screen'>
    <Throbber className='absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2' />
  </div>*/

  return !section ? (
    <Navigate to='dashboard' />
  ) : (
      <>
        <Modal
            show={logoutWarnModal}
            onRequestClose={() => setLogoutWarnModal(false)}
            width='fit'
            title='Peringatan!'
            body={
              <>
                Apakah yakin kamu ingin keluar akun?
              </>
            }
            footer={
              <div className='flex justify-end gap-3'>
                <Button
                    variant='neutral'
                    onClick={() => setLogoutWarnModal(false)}
                    leftIcon={<XMarkIcon />}
                >
                  Tidak
                </Button>
                <Button
                    loading={loading}
                    variant='danger'
                    onClick={() => {
                      dispatch(logout())}
                    }
                    leftIcon={<ArrowRightStartOnRectangleIcon />}
                >
                  Iya
                </Button>
              </div>
            }
        />
    <div className='h-[calc(100dvh)] w-screen lg:flex'>
      <div className={coverStyle} onClick={() => setNav(false)} />

      <SideNav
        routeTree={routeTree}
        currentRoute={pathArray[0]}
        show={nav}
        onRequestClose={() => setNav(false)}
      />

      <div className='relative flex flex-shrink flex-grow flex-col overflow-x-hidden'>
        {/* Top Nav */}
        <div className='absolute left-0 right-0 top-0 z-[5] flex items-center bg-neutral-10/90 p-4 shadow-sm backdrop-blur-md lg:p-6'>
          <button
            className='rounded-[6px] border-[1px] border-neutral-40 p-[4px] lg:hidden'
            onClick={() => setNav(true)}
          >
            <Bars3Icon className='h-4 w-4' />
          </button>
          <p className='flex-grow text-center text-xl font-semibold lg:text-left'>
            {section.name}
          </p>
          <ArrowRightStartOnRectangleIcon onClick={() => {
            setLogoutWarnModal(true)
          }} className='h-6 w-6 cursor-pointer' />
        </div>

        <div className='flex h-[calc(100dvh)] flex-col gap-4 overflow-hidden overflow-y-auto p-4 pt-[calc(60px+16px)] lg:gap-6 lg:p-6 lg:pt-[calc(76px+16px)]'>
          {pathArray.length > 1 ? (
            <Breadcrumb
              routeTree={routeTree}
              basePath='/'
              pathArray={pathArray}
            />
          ) : undefined}

          <Outlet />
        </div>
      </div>
    </div>
      </>
  );
}
