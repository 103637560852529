import {createSlice} from '@reduxjs/toolkit';
import toast from "react-hot-toast";

const initialState = {
    withdraws: [],
    balances: [],
    stats: {
        transaction_volume: 0,
        total_transactions: 0,
        client_balances: 0,
        app_profits: 0
    },
    withdraw: null,
    transactions: [],
    banks: [],
    paginator: [],
    loading: false,
    errorMsg: '',
    errors: {}
};

const financialSlice = createSlice({
    name: 'financial',
    initialState,
    reducers: {
        setWithdraws: (state, action) => {
            state.withdraws = action.payload;
        },
        setBalances: (state, action) => {
            state.balances = action.payload;
        },
        setWithdraw: (state, action) => {
            toast.success('Sukses melakukan penarikan sebesar ' + new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'IDR',
                    maximumFractionDigits: 0
                }).format(action.payload.amount_transferred));
            state.withdraw = action.payload;
        },
        setStats: (state, action) => {
            state.stats = action.payload;
        },
        setTransactions: (state, action) => {
            state.transactions = action.payload;
        },
        setPaginator: (state, action) => {
            state.paginator = action.payload;
        },
        getErrors: (state, action) => {
            state.errorMsg = action.payload.errorMsg;
            state.errors = action.payload.errors;
        },
        clearErrors: (state) => {
            state.errorMsg = '';
            state.errors = '';
        },
        loading: (state, action) => {
            state.loading = action.payload;
        }
    },
});

export const { loading, setWithdraws, setWithdraw, setBalances, setStats, setPaginator, setTransactions,  clearErrors, getErrors } = financialSlice.actions;
export default financialSlice.reducer;
