import axios from "axios";
import {headers} from "@/services/headers"
import {ThunkAction} from "@reduxjs/toolkit";
import {RootState} from "../store";
import {
    getErrors,
    loading,
    setPaginator,
    setTransactions, setStats, setWithdraws, setWithdraw, setBalances
} from "@/reducers/financial";

export const GetTransactions = (query?: string, page = 1):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    const response = axios
        .get(process.env.REACT_APP_BASE_URL + '/employee/financial/revenues?filter[search]=' + query + '&page=' + page, {
            headers: headers()
        }).then((response) => {
            dispatch(setTransactions(response.data.data));
            dispatch(setPaginator(response.data.meta));
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));
        });

    dispatch(loading(false))

    return response;
};

export const GetBalances = (query?: string, page = 1):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    return axios
        .get(process.env.REACT_APP_BASE_URL + '/employee/financial/organizers/balances?filter[search]=' + query + '&page=' + page, {
            headers: headers()
        }).then((response) => {
            dispatch(setBalances(response.data.data));
            dispatch(setPaginator(response.data.meta));

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });
};

export const GetWithdraws = (query?: string, page = 1):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    return axios
        .get(process.env.REACT_APP_BASE_URL + '/employee/financial/withdraws?filter[search]=' + query + '&page=' + page, {
            headers: headers()
        }).then((response) => {
            dispatch(setWithdraws(response.data.data));
            dispatch(setPaginator(response.data.meta));

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });
};

export const ApproveWithdraw = (withdrawId: string):  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    return axios
        .patch(process.env.REACT_APP_BASE_URL + '/employee/financial/withdraws/' + withdrawId, {},{
            headers: headers()
        }).then((response) => {
            dispatch(setWithdraw(response.data.data))
            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });
};

export const GetFinancialStats = ():  ThunkAction<void, RootState, unknown, any> => async (dispatch: any) => {
    dispatch(loading(true))

    return axios
        .get(process.env.REACT_APP_BASE_URL + '/employee/financial/stats', {
            headers: headers()
        }).then((response) => {
            dispatch(setStats(response.data.data));

            dispatch(loading(false))
        }).catch((error: any) => {
            const {errors, message} = error.response.data.error;

            dispatch(getErrors({
                errorMsg: message,
                errors: errors,
            }));

            dispatch(loading(false))
        });
};
